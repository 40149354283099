<template>
  <div id="RespostasCompartilhadasTabela">
    <div v-if="Consultado">
      <section class="tabela-empresas" v-if="Consultado">
        <hr class="mb-2 mt-2" />

        <section>
          <!-- Visualizar e input pesquisar -->
          <b-row id="filtros" class="mb-1">
            <b-col sm="2" class="mb-1">
              <div class="d-flex flex-row align-items-center">
                <label class="label">Visualizar</label>
                <b-form-select
                  v-model="dadosPaginacao.quantidadeLinhasPadrao"
                  size="sm"
                  class="w-10"
                  id="amounlinesVisualizer"
                  label="option"
                  @input="atualizaQuantidadeVisualizar($event)"
                  :options="tabela.quantidadeLinhasVisualizar"
                  :disabled="!existeTabelaParaRenderizar"
                />
              </div>
            </b-col>
            <b-col lg="7" sm="6"></b-col>
            <b-col lg="3" sm="6" class="mb-1">
              <div class="d-flex flex-row align-items-center">
                <label class="label">Busca</label>
                <b-form-input
                  v-model="campoNome"
                  :disabled="desabilitarCampoNome"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <!-- Visualizar e input pesquisar -->

          <!-- Tabela -->
          <b-row>
            <b-table
              id="listAcompanhamentosTable"
              responsive
              @context-changed="ordenarTabela"
              sticky-header="100%"
              :busy.sync="tabela.tabelaOcupada"
              :no-local-sorting="true"
              :fields="tabela.fields"
              :items="tabela.items"
              show-empty
            >
              <template #cell(iconeRiscoVida)>
                <img
                  src="@/assets/custom-icons/coracaoRiscoVida.svg"
                  alt="ícone Risco à vida"
                />
              </template>

              <template #empty>
                <b-row>
                  <b-col>
                    <div class="tabela-vazia">
                      <span v-if="loading.pesquisando">Carregando...</span>
                      <span v-else
                        ><strong>Nenhum registro encontrado.</strong> Realize
                        novamente uma busca utilizando diferentes
                        critérios.</span
                      >
                    </div>
                  </b-col>
                </b-row>
              </template>

              <!-- Coluna Escala -->
              <template #cell(escala)="row">
                <b-badge
                  pill
                  class="badge-escala"
                  :variant="deParaCorEscala(row.item)"
                  >{{ deParaTextoEscala(row.item) }}</b-badge
                >
              </template>

               <!-- Coluna contato_realizado -->
               <template #cell(contato_realizado)="row">
                <span
                  v-text="
                    verificandoContatoRealizado(row.item.contato_realizado)
                  "
                ></span>
              </template>

              <!-- Coluna Compartilhado Com -->
              <template #cell(compartilhado_com)="row">
                <span
                  v-html="
                    deParaTextoCompartilhadoCom(row.item)
                  "
                ></span>
              </template>

              <!-- Coluna Resposta -->
              <template #cell(resposta)="row">
                <b-col lg="10" md="12" class="mx-auto p-0">
                  <div class="d-flex justify-content-center">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="mostraModalAcompanhamento(row.item)"
                    >
                      <feather-icon icon="BookOpenIcon" />
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      class="btn-icon ml-1"
                      @click="mostraModalFichaColaborador(row.item)"
                      ><feather-icon icon="UserIcon"
                    /></b-button>
                  </div>
                </b-col>
              </template>
            </b-table>
          </b-row>
          <!-- Tabela -->

          <!-- Paginação -->
          <b-row v-if="existeTabelaParaRenderizar"
            ><b-col sm="12" class="mt-2">
              <CustomPagination
                :paginacao="dadosPaginacao"
                @page-cliked="atualizarPaginaAtual"
              /> </b-col
          ></b-row>
          <!-- Paginação -->
        </section>
      </section>

      <RespostasCompartilhadasTabelaModal
        v-if="dadosAcompanhamento.itens"
        :dadosAcompanhamento="dadosAcompanhamento"
        @fechaModal="fechaModal"
      />

      <!-- modal ficha colaborador -->
      <b-modal
        v-model="fichaColaborador.showModal"
        id="modal-empresa"
        ref="modal-empresa"
        size="custom"
        centered
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
      >
        <VisualizarPessoaForm
          v-if="true"
          :propEditPessoa="fichaColaborador.pessoaEdit"
          :ModoVisualizacao="fichaColaborador.somenteLeitura"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  BCardText,
  BModal,
  BSpinner,
  BFormSelect,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import RespostasCompartilhadasTabelaModal from "./RespostasCompartilhadasTabelaModal.vue";
import VisualizarPessoaForm from "@/views/pages/grupos/VisualizarPessoaForm.vue";

export default {
  props: {
    FaseSelecionada: {
      required: true,
    },
    Consultado: {
      type: Boolean,
      required: true,
    },
    Empresa: {
      type: Object,
      required: true,
    },
    RiscoVida: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BBadge,
    BCardText,
    BModal,
    BRow,
    BCol,
    vSelect,
    BButton,
    BTable,
    BSpinner,
    BFormSelect,
    BFormInput,
    CustomPagination,
    RespostasCompartilhadasTabelaModal,
    VisualizarPessoaForm
  },
  data() {
    return {
      fichaColaborador: {
        pessoaEdit: {},
        showModal: false,
        somenteLeitura: true,
      },
      loading: {
        pesquisando: false,
        pagina: true,
      },
      campoNomeFocar: false,
      campoNome: "",
      dadosAcompanhamento: {
        nome: null,
        risco_vida: this.RiscoVida,
        itens: [],
        questionario: null,
      },
      tabela: {
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        campoOrdenado: "escala",
        ordem: "desc",
        ordemInicial: false,
        fields: [
          {
            key: "iconeRiscoVida",
            label: "",
            sortable: false,
            class: !this.RiscoVida ? "d-none" : "iconeRiscoVida",
          },
          { key: "nome", label: "Colaborador", sortable: true },
          {
            key: "setor",
            label: "Setor",
            class: "text-center",
            sortable: true,
          },
          {
            key: "escala",
            label: "Escala",
            class: "text-center",
            sortable: true,
          },
          {
            key: "contato_realizado",
            label: "Contato Realizado",
            class: "text-center",
            sortable: true,
          },
          { key: "id_fase", label: "Fase", class: "text-center" },
          {
            key: "compartilhado_com",
            label: "Compartilhado com",
            class: "text-center",
          },
          { key: "resposta", label: "Resposta", class: "text-center" },
        ],
        items: [],
      },
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
    };
  },
  beforeMount() {
    this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
  },
  watch: {
    Consultado: function () {
      this.dadosPaginacao.ordemInicial = true;
      this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
    },
    campoNome: {
      handler: function (valorAntigo) {
        this.campoNomeFocar = valorAntigo.length > 0;
        this.dadosPaginacao.paginaAtual = 1;
        this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
      },
    },
  },
  computed: {
    existeTabelaParaRenderizar() {
      return this.tabela.items.length > 0;
    },
    desabilitarCampoNome() {
      return (
        !this.tabela.items.length > 0 &&
        this.campoNome == "" &&
        !this.campoNomeFocar
      );
    },
  },
  methods: {
    fechaModal() {
      this.dadosPaginacao.ordemInicial = true;
      this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
    },
    buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo() {
      this.tabela.items = [];

      if (this.Consultado) {
        this.loading.pesquisando = true;

        let rotaConsulta = this.$api.AcompanhamentoRespostasDoCicloCompartilhadaRiscoVida;
        let rota = this.$helpers.preparaRotaComParametros(
          rotaConsulta,
          "{fase}",
          this.FaseSelecionada
        );

        this.$http
          .get(rota, {
            params: this.lidarComParametrosBuscaRespostasCompartilhadas(),
          })
          .then((res) => {
            if (res.status != 204) {
              let resposta = res.data;
              this.tabela.items = resposta.data;
              this.IniciarPaginacao(resposta);
            }
            this.loading.pesquisando = false;
            this.dadosPaginacao.ordemInicial = false;
          });
      }
    },
    lidarComParametrosBuscaRespostasCompartilhadas() {
      if(this.RiscoVida){
        return {
          nome: this.campoNome,
          colunaNome: this.tabela.campoOrdenado,
          colunaOrdem: this.tabela.ordem,
          perPage: this.dadosPaginacao.quantidadeLinhasPadrao,
          page: this.dadosPaginacao.paginaAtual,
          ordemInicial: this.dadosPaginacao.ordemInicial,
          listaCompartilhadas: true
        };
      }else{
        return {
          nome: this.campoNome,
          colunaNome: this.tabela.campoOrdenado,
          colunaOrdem: this.tabela.ordem,
          perPage: this.dadosPaginacao.quantidadeLinhasPadrao,
          page: this.dadosPaginacao.paginaAtual,
          ordemInicial: this.dadosPaginacao.ordemInicial
        };
      }

    },
    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from;
      this.dadosPaginacao.ateALinha = dadosPaginacao.to;
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
    },
    mostraModalAcompanhamento(acompanhamento) {
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.AcompanhamentoBuscarRespostasPorIdAcompanhamento,
        {
          "{idAcompanhamento}": acompanhamento.id_acompanhamento
        }
      );

      this.dadosAcompanhamento.nome = null;
      this.dadosAcompanhamento.itens = [];
      this.dadosAcompanhamento.id_acompanhamento =
        acompanhamento.id_acompanhamento;
      this.dadosAcompanhamento.protecao_vida = this.RiscoVida;

      this.$bvModal.show("modal-acompanhamento");
      this.$http.get(rota).then((res) => {
        let respostas = res.data.data;
          let parteUm = null;
          let parteDois = null;
          let parteTres = null;

          if(acompanhamento.questionario == 'DASS-21'){
              parteUm = respostas.slice(0, 8);
              parteDois = respostas.slice(8,17);
              parteTres = respostas.slice(17);
          }

          if(acompanhamento.questionario == 'PHQ-9'){
              parteUm = respostas.slice(0, 7);
              parteDois = respostas.slice(7);
          }

        this.dadosAcompanhamento.nome = acompanhamento.nome;
        this.dadosAcompanhamento.questionario = acompanhamento.questionario
        this.dadosAcompanhamento.itens = {
          parteUm,
          parteDois,
          parteTres
        };
      });
    },
    mostraModalFichaColaborador(colaborador) {
      this.fichaColaborador.showModal = true;
      this.fichaColaborador.pessoaEdit = colaborador;
    },
    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

      this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
    },
    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page;
      this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
    },
    atualizaQuantidadeVisualizar(qtdSelecionada) {
      let qtd = !qtdSelecionada ? 10 : qtdSelecionada;
      this.dadosPaginacao.quantidadeLinhasPadrao = qtd;
      this.dadosPaginacao.paginaAtual = 1;
      this.buscarAcompanhamentoRespostasDoCicloCompartilhadaComigo();
    },

    deParaTextoEscala(item) {
      if(item.questionario == 'PHQ-9'){
          let convertido;
          switch (item.escala) {
            case 0:
              convertido = "0 - Sem Sintomas";
              break;
            case 1:
              convertido = "1 - Sintomas Leves";
              break;
            case 2:
              convertido = "2 - Sintomas Mínimos";
              break;
            case 3:
              convertido = "3 - Sintomas Moderados";
              break;
            case 4:
              convertido = "4 - Sintomas Graves";
              break;
            default:
              convertido = "---";
          }

        return convertido;
      }

      if(item.questionario == 'DASS-21'){
        let convertido;
          switch (item.escala) {
            case 0:
              convertido = "0 - Sem Sintomas";
              break;
            case 1:
              convertido = "1 - Sintomas Leves";
              break;
            case 2:
              convertido = "2 - Sintomas Moderados";
              break;
            case 3:
              convertido = "3 - Sintomas Graves";
              break;
            case 4:
              convertido = "4 - Sintomas Muito Graves";
              break;
            default:
              convertido = "---";
          }

        return convertido;
      }

    },

    deParaCorEscala(item) {
      if(item.questionario == 'PHQ-9'){
        let convertido;
        switch (item.escala) {
          case 0:
            convertido = "escala-sem-sintomas";
            break;
          case 1:
            convertido = "escala-sintomas-leves";
            break;
          case 2:
            convertido = "escala-sintomas-minimos";
            break;
          case 3:
            convertido = "escala-sintomas-moderados";
            break;
          case 4:
            convertido = "escala-sintomas-graves";
            break;
          default:
            convertido = "---";
        }

        return convertido;
      }

      if(item.questionario == 'DASS-21'){
        let convertido;
        switch (item.escala) {
          case 0:
            convertido = "escala-sem-sintomas";
            break;
          case 1:
            convertido = "escala-sintomas-leves";
            break;
          case 2:
            convertido = "escala-sintomas-moderados";
            break;
          case 3:
            convertido = "escala-sintomas-graves";
            break;
          case 4:
            convertido = "escala-sintomas-muito-graves";
            break;
          default:
            convertido = "---";
        }

        return convertido;
      }

    },

    verificaEscalaDass(escala) {
        return `${escala.escala } - ${escala.escala_descricao}`
    },

    verificandoContatoRealizado(contatoRealizado) {
      return contatoRealizado ? "Sim" : "Não";
    },
    deParaTextoCompartilhadoCom(item) {
        if(this.RiscoVida){
          const roles = [];

          if (item.com_rh) roles.push("RH");
          if (item.com_saude) roles.push("Saúde");
          if (item.com_lider) roles.push("Líder");
          if(item.com_saude == false && roles.length != 0) roles.push("Saúde <b>*</b>");

          if (roles.length === 0) return "Saúde <b>*</b>";

          let texto = roles.join(", ");
          return texto;
        }else{
            const roles = [];

            if (item.com_rh) roles.push("RH");
            if (item.com_saude) roles.push("Saúde");
            if (item.com_lider) roles.push("Líder");

            let texto = roles.join(", ");
            return texto;
        }

    },
  },
};
</script>
<style>
td.iconeRiscoVida {
  background: #ea5455;
  color: white;
  width: 25px;
}
th.iconeRiscoVida {
  width: 25px;
}
</style>
