<template>
  <!-- modal acompanhamento -->
  <b-modal
    id="modal-acompanhamento"
    centered
    :title="dadosAcompanhamento.nome"
    size="custom"
    v-if="dadosAcompanhamento.itens"
    @close="fechaModal"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <section v-if="!loadingShow">
        <b-row
          v-show="dadosAcompanhamento.risco_vida"
          class="modal-risco-vida mb-2 px-1"
        >
          <b-col class="alerta-risco-vida">
            <span>Risco à vida</span>
            <img
              src="@/assets/custom-icons/coracaoRiscoVida.svg"
              alt="ícone Risco à vida"
            />
          </b-col>
        </b-row>

        <b-row
          :class="dadosAcompanhamento.risco_vida ? 'modal-risco-vida' : ''"
        >
          <b-col lg="12" class="mb-2">
            <h2 class="h1" v-text="dadosAcompanhamento.nome"></h2>
          </b-col>

          <div :class="{'col-4': dadosAcompanhamento.itens.parteTres != null, 'col-6': dadosAcompanhamento.itens.parteTres == null}">
            <ul class="list-group">
              <li
                v-for="(item, contador) in dadosAcompanhamento.itens.parteUm"
                :key="item.questao"
                :class="`um-pergunta-${contador}`"
              >

                 <p  v-html="item.questao"></p>
                <p class="h5" v-html="item.opcao"></p>
              </li>
            </ul>
          </div>
          <div :class="{'col-4': dadosAcompanhamento.itens.parteTres != null, 'col-6': dadosAcompanhamento.itens.parteTres == null}">
            <ul class="list-group"> 
              <li
                v-for="(item, contador) in dadosAcompanhamento.itens.parteDois"
                :key="item.questao"
                :class="{'dois-pergunta-1': (item.id_questionario == 5 || item.id_questionario == 1) && (item.id_questao == 11) }"
              >
                <p  v-html="item.questao"></p>
                <p :class="{ 'h5': (item.opcao == '<b>Nunca</b>' || item.opcao == '<b>Poucas vezes</b>' || item.questao != '<b>(21/21)</b> Senti que a vida não tinha sentido.' ),'dois-pergunta-1' : (item.questao == '<b>(21/21)</b> Senti que a vida não tinha sentido.' && (item.opcao == '<b>O tempo todo</b>' || item.opcao == '<b>Muitas vezes</b>') )}" v-html="item.opcao"></p>
              </li>
            </ul>
          </div>

          <div :class="{'col-4': dadosAcompanhamento.itens.parteTres != null}">
            <ul class="list-group"> 
              <li
                v-for="(item, contador) in dadosAcompanhamento.itens.parteTres"
                :key="item.questao"
                :class="{'dois-pergunta-1': (item.id_questionario == 5 || item.id_questionario == 1) && (item.id_questao == 11) }"
              >
                <p  v-html="item.questao"></p>
                <p :class="{ 'h5': (item.opcao == '<b>Nunca</b>' || item.opcao == '<b>Poucas vezes</b>' || item.questao != '<b>(21/21)</b> Senti que a vida não tinha sentido.' ),'dois-pergunta-1' : (item.questao == '<b>(21/21)</b> Senti que a vida não tinha sentido.' && (item.opcao == '<b>O tempo todo</b>' || item.opcao == '<b>Muitas vezes</b>') )}" v-html="item.opcao"></p>
              </li>
            </ul>
          </div>
        </b-row>
      </section>

      <RespostasCompartilhadasCheckDeRealizacao
        v-if="!loadingShow"
        :dadosAcompanhamento="dadosAcompanhamento"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import { BOverlay, BRow, BCol, BModal } from "bootstrap-vue";
import RespostasCompartilhadasCheckDeRealizacao from "./RespostasCompartilhadasCheckDeRealizacao.vue";
export default {
  props: {
    dadosAcompanhamento: {
      type: Object,
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    BModal,
    BOverlay,
    RespostasCompartilhadasCheckDeRealizacao,
  },
  methods: {
    fechaModal() {
      this.$emit("fechaModal");
    },
  },
  computed: {
    loadingShow() {
      return this.dadosAcompanhamento.itens.length == 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-risco-vida {
  .alerta-risco-vida {
    background: #ea5455;
    padding: 5px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: block;
  }
  .alerta-risco-vida span {
    padding: 6px 45px 6px 0;
    display: inline-block;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 100%;
    vertical-align: Top;
    letter-spacing: 1px;
  }
  .dois-pergunta-1 p.h5 {
    color: #ea5455 !important;
  }
}
.dois-pergunta-1 {
    color: #ea5455 !important;
  }
</style>
