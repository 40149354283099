<template>
  <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
    <section>
      <validation-observer ref="simpleRules">
        <b-form
          id="CheckDeRealizacaoForm"
          ref="CheckDeRealizacaoForm"
          @submit.prevent="SalvarDados()"
          class="mt-2"
        >
          <!-- avisos -->
          <b-row class="mb-1" v-if="mensagens.length > 0" v-model="mensagens">
            <b-col>
              <b-alert
                v-for="(item, index) in mensagens"
                :key="index"
                :variant="item.variant"
                show
                class="w-100 p-1"
              >
                <span v-html="item.mensagem"></span>
              </b-alert>
            </b-col>
          </b-row>
          <!-- avisos -->

          <b-row>
            <b-col>
              <div class="mb-1 primeiro-contato">
                <b-form-checkbox
                  v-model="form.primeiroContatoRealizado"
                  :value="true"
                  :disabled="form._method == 'put'"
                  :readonly="form.primeiroContatoRealizado"
                  class="custom-control-danger"
                >
                  O primeiro contato com essa pessoa já foi realizado
                </b-form-checkbox>
              </div>

              <div class="d-inline-block" v-if="form._method == 'put'">
                <p class="d-inline pl-1">Marcado em: {{ form.criacao }}</p>
              </div>

              <b-form-group label="Observações:" label-for="observacoes">
                <b-form-textarea
                  id="observacoes"
                  v-model="form.observacao"
                ></b-form-textarea>

                <small v-if="!limiteCaracteres" class="text-danger"
                  >O campo Observação não pode conter mais de
                  {{ form.qtdCaracteresObservacao }} caracteres.<br
                /></small>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-center pt-1"
              ><b-button
                type="submit"
                variant="custom"
                :disabled="bloquearBotaoEnviarform || !limiteCaracteres"
              >
                Registrar Informações
              </b-button></b-col
            >
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BFormGroup,
  BForm,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
export default {
  props: {
    dadosAcompanhamento: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BFormGroup,
    BForm,
    BFormTextarea,
    BAlert,
  },
  data() {
    return {
      loadingShow: true,
      mensagens: [],
      form: {
        url: "",
        _method: "post",
        id_acompanhamento_observacao: null,
        id_acompanhamento: this.dadosAcompanhamento.id_acompanhamento,
        observacao: "",
        primeiroContatoRealizado: false,
        qtdCaracteresObservacao: 500,
        criacao: null,
      },
    };
  },
  beforeMount() {
    this.consultaCheckRealizacao();
  },
  methods: {
    setMensagens(sucesso = null, mensagensData = null) {
      let mensagens = [];

      if (sucesso != null) {
        mensagens.push({
          mensagem: mensagensData.join("<br>"),
          class: sucesso ? "success" : "danger",
          variant: sucesso ? "success" : "danger",
        });
      }

      this.mensagens = mensagens;
    },
    setDataCriacao(dataCriacao) {
      this.form.criacao =
        this.$helpers.converteDateTimeBancoParaHumano(dataCriacao);
    },
    setForm(observacaoData, metodo) {
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.ObservacoesCadastrar,
        {
          "{idAcompanhamento}": observacaoData.id_acompanhamento,
          "{protecaoVida}": this.dadosAcompanhamento.protecao_vida,
        }
      );

      if (metodo == "put") {
        rota = this.$helpers.preparaRotaComMultiplosParametros(
          this.$api.ObservacoesAtualizar,
          {
            "{idAcompanhamento}": observacaoData.id_acompanhamento,
            "{idAcompanhamentoObservacao}":
              observacaoData.id_acompanhamento_observacao,
            "{protecaoVida}": this.dadosAcompanhamento.protecao_vida,
          }
        );
      }

      this.form = {
        url: rota,
        _method: metodo,
        id_acompanhamento_observacao:
          observacaoData.id_acompanhamento_observacao,
        id_acompanhamento: observacaoData.id_acompanhamento,
        observacao: observacaoData.observacao,
        primeiroContatoRealizado: metodo == "put",
        qtdCaracteresObservacao: 500,
        protecao_vida: this.dadosAcompanhamento.protecao_vida,
      };
    },
    async consultaCheckRealizacao() {
      this.setMensagens();
      this.loadingShow = false;

      const RotaObservacoesConsultar =
        this.$helpers.preparaRotaComMultiplosParametros(
          this.$api.ObservacoesConsultarPorIdAcompanhamento,
          {
            "{idAcompanhamento}": this.dadosAcompanhamento.id_acompanhamento,
            "{protecaoVida}": this.dadosAcompanhamento.protecao_vida,
          }
        );

      this.loadingShow = true;
      try {
        await this.$http.get(RotaObservacoesConsultar).then((res) => {
          if (res.status == 200) {
            this.setForm(res.data.data, "put");
            this.setDataCriacao(res.data.data.criacao);
          } else {
            this.setForm(this.form, "post");
          }
        });
      } catch (error) {
        this.loadingShow = false;
      }
      this.loadingShow = false;
    },
    formataDadosFormulario() {
      return {
        id_acompanhamento: this.form.id_acompanhamento,
        id_acompanhamento_observacao: this.form.id_acompanhamento_observacao,
        observacao: this.form.observacao,
        protecao_vida: this.form.protecao_vida,
        _method: this.form._method,
      };
    },
    marcarNotificacaoLida() {
      try {
        let rota = this.$helpers.preparaRotaComMultiplosParametros(
          this.$api.AtualizarNotificacaoPorAcompanhamento,
          {
            "{acompanhamento}": this.form.id_acompanhamento,
          }
        );
        this.$http.put(rota).then((res) => {
          return res.status == 200;
        });
      } catch (error) {
        return false;
      }
    },
    async SalvarDados() {
      let FormData = this.formataDadosFormulario();
      this.loadingShow = true;
      this.setMensagens();
      try {
        await this.$http.post(this.form.url, FormData).then((res) => {
          let mensagem =
            this.form._method == "post"
              ? "Observação cadastrada com sucesso."
              : "Observação atualizada com sucesso.";

          this.setForm(res.data.data, "put");
          this.setDataCriacao(res.data.data.criacao);
          this.setMensagens(true, [mensagem]);
          if (this.form.primeiroContatoRealizado) {
            this.marcarNotificacaoLida();
          }
        });
      } catch (error) {
        this.loadingShow = false;
        let mensagens = [
          "Erro ao salvar os dados. Sistema de busca indisponível no momento.",
        ];

        if (error.response.status == 422) {
          let erros = error.response.data.error
            ? error.response.data.error
            : error.response.data.errors;

          mensagens = Object.entries(erros).map((entry) => {
            entry.splice(0, 1);
            return entry[0].join("<br>");
          });
        }

        this.setMensagens(false, mensagens);
      }
      this.loadingShow = false;
    },
  },
  computed: {
    limiteCaracteres() {
      if (this.form.observacao == null) {
        return true;
      }
      return this.form.observacao.length <= this.form.qtdCaracteresObservacao;
    },
    bloquearBotaoEnviarform() {
      return !this.form.primeiroContatoRealizado;
    },
  },
};
</script>

<style lang="scss">
#CheckDeRealizacaoForm {
  .primeiro-contato {
    display: inline-block;
    background: #fdeeee;
    padding: 5px 10px;
    border-radius: 5px;
    .custom-checkbox {
      padding-top: 3px;
    }
    .custom-control-label {
      color: #6e6b7b;
      font-size: 14px;
      line-height: 17px;
      line-height: 100%;
      font-weight: 400;
    }
  }
}
</style>
