<template>
  <b-card id="RespostasCompartilhadas">
    <b-row>
      <b-col class="col-12"><h1 v-text="Meta.titulo"></h1></b-col>
      <b-col class="col-12">
        <p v-if="Meta.riscoVida" v-text="Meta.mensagem.comRisco"></p>
        <p v-else v-text="Meta.mensagem.semRisco"></p>
      </b-col>
    </b-row>

    <b-row class="mb-1" id="section-ciclo">
      <b-col lg="3" md="4" class="header-ciclo"
        ><h3 class="h3-ciclo">Escolha uma Fase</h3></b-col
      >
      <b-col lg="7" md="5" class="mb-1">
        <b-form-select
          v-model="FaseSelecionada"
          :options="Fases"
          placeholder="Aguarde um momento..."
          style="height: 40px"
        >
          <template #first v-if="Fases.length == 0">
            <b-form-select-option value="null" disabled selected
              >Aguarde um momento...</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
      <b-col lg="2" md="3" class="mb-1">
        <b-button
          variant="custom"
          :disabled="desabilitarBotao"
          @click="consultarRespostasCiclo"
          >Selecionar</b-button
        >
      </b-col>
    </b-row>

    <RespostasCompartilhadasTabela
      v-if="FaseSelecionada"
      :FaseSelecionada="FaseSelecionada"
      :Consultado="Consultado"
      :Empresa="Empresa"
      :RiscoVida="Meta.riscoVida"
    />

    <p id="quebra-sigilo-aviso" class="mt-1" v-show="Meta.riscoVida">
      <strong>*Quebra de sigilo.</strong> A divulgação dos dados ocorre na
      hipótese indispensável para a proteção da vida ou da incolumidade física
      do colaborador. Segundo o
      <a href="https://lgpd-brasil.info/capitulo_02/artigo_11" target="_blank"
        >Artigo 11 da Lei Nº 13.709 do Marco Civil da Internet.</a
      >
    </p>
  </b-card>
</template>

<script>
import {
  BRow,
  BCard,
  BCol,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import RespostasCompartilhadasTabela from "./RespostasCompartilhadasTabela";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCard,
    BCol,
    BButton,
    BFormSelect,
    BFormSelectOption,
    RespostasCompartilhadasTabela,
  },
  data() {
    return {
      Fases: [],
      FaseSelecionada: null,
      Consultado: false,
      Usuario: null,
      Empresa: null,
      Meta: {
        titulo: "Respostas Compartilhadas",
        riscoVida: false,
        mensagem: {
          semRisco:
            "Toda informação nessa página é confidencial, somente você deverá ter acesso a ela.",
          comRisco:
            "As respostas compartilhadas nessa pagina são de pessoas que apresentaram respostas que indicam risco à sua integridade física ou ou a sua própria vida. Para garantir a segurança desta pessoa, as respostas foram compartilhadas com você, profissional da Saúde. Realize o acolhimento e direcionamento desta pessoa o mais breve possível.",
        },
      },
    };
  },
  beforeMount() {
    const usuario = getUserData();
    this.Usuario = usuario.usuario;
    this.Empresa = usuario.empresa;
    this.setInformacoesMeta();
    this.getFasesEmpresa(); 
  },
  computed: {
    desabilitarBotao: function () {
      return this.FaseSelecionada == null || this.Consultado;
    },
  },
  watch: {
    FaseSelecionada: function () {
      this.Consultado = false;
    },
    "$route.meta.protecao_vida": function () {
      this.setInformacoesMeta();
    },
  },
  methods: {
    getFasesEmpresa() {
      let fasesFormatadas = [],
        rota = this.$helpers.preparaRotaComParametros(
          this.$api.AcompanhamentoBuscarFasesPorIdEmpresa,
          "{idEmpresa}",
          this.Empresa.id_empresa
        );

      this.$http.get(rota).then((res) => {
        let resposta = res.data.data;

        resposta.sort((a, b) => a.descricao.localeCompare(b.descricao));

        if (resposta.length == 0) {
          fasesFormatadas.push({
            value: "null",
            text: "Nenhum registro encontrado",
            disabled: true,
            selected: true,
          });
        } else {
          fasesFormatadas.push({
            value: "null",
            text: "Selecione um ciclo...",
            disabled: true,
          });
          fasesFormatadas.push({
            value: "todos",
            text: "Todos",
          });
          resposta.forEach((fase) => {
            let descricaoFase = fase.descricao.replace("Ciclo", "Fase");
            fasesFormatadas.push({ value: fase.id_fase, text: descricaoFase });
          });
        }

        this.Fases = fasesFormatadas;
      });
    },
    consultarRespostasCiclo() {
      this.Consultado = true;
    },
    setInformacoesMeta() {
      this.$route.params.idGrupo = this.Empresa.empresa_grupo.id_empresa_grupo;
      this.FaseSelecionada = null;
      this.Consultado = false;

      this.Meta.riscoVida = this.$route.meta.protecao_vida;

      this.Meta.titulo = this.Meta.riscoVida
        ? "Respostas com Risco"
        : "Respostas Compartilhadas";
    },
    handleNotificationUpdateUserNotificationAsRead() {
      if (
        this.$route.params.notificationUpdateUserNotificationAsRead != undefined
      ) {
        let item = this.$route.params.notificationUpdateUserNotificationAsRead;
        this.$store.dispatch("notifications/updateUserNotificationAsRead", {
          idNotificacaoUsuario: item.id_notificacao_usuario,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/custom/respostas-compartilhadas.scss";
</style>
